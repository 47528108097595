.bank-and-code-selector {
  @extend .form__item--horizontal;

  .bank-and-code-selector__label {
    @extend .form__item__label;
  }

  .bank-and-code-selector__input-container {
    @extend .form__item__input;

    .react-select {
      margin-bottom: 16px;
    }
  }

  &.has-error {
    @extend .form__item--horizontal;

    .bank-and-code-selector__input-container {
      border: 1px solid $clr-brand;
    }
  }
}

.bank-and-code-selector__input-container__input {
  @include push--smaller--bottom;
  height: 32px;
  padding: 0 8px;
}

.bank-and-code-selector__input-container__input--error {
  border-color: $clr-brand;
}

@include media-query(portable){
  .bank-and-code-selector__label, .bank-and-code-selector__input-container {
    flex-basis: 100% !important;
  }
}
